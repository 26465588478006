import * as React from 'react';
import type { HeadFC } from 'gatsby';
import { graphql } from 'gatsby';
import { SEO } from '../components/seo/seo';
import { Layout } from '../components/layout/layout';
import PageLinks from '../components/page-links/page-links';
import { Vacancy } from '../sdk/entities/Vacancy';
import { WorkField } from '../sdk/entities/WorkField';
import VacanciesList from '../components/vacancies-list/vacancies-list';
import { Position } from '../sdk/entities/Position';
import SearchHistory from '../components/search-history/search-history';
import Faq from '../components/faq/faq';
import HomeBanner from '../components/home-banner/home-banner';
import ProductInfo from '../components/product-info/product-info';
import PopularWorkfields from '../components/popular-workfields/popular-workfields';
import { faq } from '../components/faq/faq-data';

export type IndexPageProps = {
  data: {
    allVacancy: {
      nodes: Vacancy[];
    };
    allWorkField: {
      nodes: WorkField[];
    };
    allPosition: {
      nodes: Position[];
    };
  };
};

const IndexPage: React.FC<IndexPageProps> = ({ data }) => {
  return (
    <main>
      <Layout
        headerTitle="Encontre a vaga perfeita para você"
        vendorIcon={true}
        mobileFilter
      >
        <SearchHistory />

        <div className="my-16">
          <VacanciesList
            title={'Novas vagas'}
            vacancies={data.allVacancy.nodes}
            queryParams={{}}
          />
        </div>

        <div className="my-28 sm:my-48">
          <PopularWorkfields />
        </div>

        <HomeBanner />

        <div className="mb-28 mt-40 sm:mb-36 sm:mt-52">
          <ProductInfo />
        </div>

        <div className="my-16">
          <PageLinks
            data={[
              {
                title: 'Áreas',
                links: data.allWorkField.nodes.map(workField => ({
                  title: workField.name,
                  url: `/${workField.slug}/`,
                })),
              },
              {
                title: 'Cargos',
                links: data.allPosition.nodes.map(position => ({
                  title: position.name,
                  url: `/${position?.workField?.slug}/${position.slug}/`,
                })),
              },
            ]}
          />
        </div>
        <div className="my-28 sm:my-32">
          <Faq />
        </div>
      </Layout>
    </main>
  );
};

export const query = graphql`
  query {
    allVacancy(
      sort: { createdAt: DESC }
      filter: { active: { eq: true } }
      limit: 5
    ) {
      nodes {
        id
        _id
        name
        maxRemuneration
        confidential
        contractModel
        description
        remuneration
        remunerationModel
        slug
        position {
          slug
          workField {
            slug
          }
        }
        workplace {
          name
          address {
            city
            complement
            country
            district
            lat
            lng
            number
            postalCode
            referencePoint
            state
            street
          }
        }
        officeDays
        image {
          childImageSharp {
            gatsbyImageData(
              width: 40
              height: 40
              transformOptions: { fit: COVER }
              quality: 80
            )
          }
        }
      }
    }
    allWorkField(sort: { name: ASC }) {
      nodes {
        id
        name
        slug
      }
    }
    allPosition(sort: { name: ASC }, limit: 50) {
      nodes {
        name
        slug
        workField {
          slug
        }
      }
    }
  }
`;

export default IndexPage;

export const Head: HeadFC = () => {
  const FaqSchema = (
    faq: {
      question: string;
      answer: string;
    }[],
  ) => {
    const faqData = faq.map(faq => {
      return {
        '@type': 'Question',
        name: faq.question,
        acceptedAnswer: {
          '@type': 'Answer',
          text: `<p>${faq.answer}</p>`,
        },
      };
    });
    return {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: [...faqData],
    };
  };
  return <SEO pathname={'/'} schema={FaqSchema(faq)} />;
};
