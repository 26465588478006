// extracted by mini-css-extract-plugin
export var address = "minimal-vacancy-card-module--address--73f76";
export var description = "minimal-vacancy-card-module--description--7abab";
export var descriptionContainer = "minimal-vacancy-card-module--descriptionContainer--bd9dd";
export var info = "minimal-vacancy-card-module--info--c6adb";
export var leftContent = "minimal-vacancy-card-module--leftContent--162bc";
export var leftContentLine = "minimal-vacancy-card-module--leftContentLine--0c87b";
export var line = "minimal-vacancy-card-module--line--c2e45";
export var link = "minimal-vacancy-card-module--link--02bb0";
export var logo = "minimal-vacancy-card-module--logo--f084e";
export var remuneration = "minimal-vacancy-card-module--remuneration--d440d";
export var rightContent = "minimal-vacancy-card-module--rightContent--e4beb";
export var separator = "minimal-vacancy-card-module--separator--f7029";
export var tag = "minimal-vacancy-card-module--tag--4dd22";
export var tagsWrapper = "minimal-vacancy-card-module--tagsWrapper--cf42a";
export var title = "minimal-vacancy-card-module--title--5a2aa";
export var vacancyCard = "minimal-vacancy-card-module--vacancyCard--4966b";
export var vacancyName = "minimal-vacancy-card-module--vacancyName--9850f";
export var workplaceInfo = "minimal-vacancy-card-module--workplaceInfo--ae190";
export var workplaceName = "minimal-vacancy-card-module--workplaceName--2d9c1";