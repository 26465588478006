import React, { useMemo } from 'react';
import * as classes from './vacancy-salary.module.scss';
import clsx from 'clsx';
import { Vacancy, VacancyRemunerationModel } from '../../sdk/entities/Vacancy';
import { BsArrowUpRight } from '@react-icons/all-files/bs/BsArrowUpRight';

interface VacancySalaryProps {
  vacancy: Vacancy;
  small?: boolean;
  iconColor?: string;
}

export default function VacancySalary(props: VacancySalaryProps) {
  const { vacancy, small, iconColor = '#71809680' } = props;

  const remuneration = useMemo(() => {
    let base: number;
    let baseText = '';
    let remunerationTotal = 0;

    if (vacancy.remunerationModel === VacancyRemunerationModel.FIXED) {
      base = vacancy.remuneration;
      baseText = '(fixo)';
      remunerationTotal = vacancy.remuneration;
    } else {
      base = (vacancy.remuneration + vacancy.maxRemuneration) / 2;
      baseText = '(média salarial)';
      remunerationTotal = vacancy.remuneration + vacancy.maxRemuneration / 2;
    }

    const formatted = base.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      maximumFractionDigits: 0,
    });

    const fullText = `${formatted} ${baseText}`;
    const total = remunerationTotal.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
      maximumFractionDigits: 0,
    });

    return {
      formatted,
      baseText,
      fullText,
      total,
    };
  }, [vacancy]);

  return (
    <div className={classes.fullContainer}>
      <div
        className={clsx({
          [classes.remunerationLine]: true,
          [classes.remunerationColumn]:
            vacancy.remunerationModel === VacancyRemunerationModel.AVERAGE,
        })}
      >
        <div
          className={clsx({
            [classes.total]: true,
            [classes.smallTotal]: small,
          })}
        >
          {vacancy.remunerationModel === VacancyRemunerationModel.FIXED &&
            remuneration.total}
          {vacancy.remunerationModel === VacancyRemunerationModel.AVERAGE && (
            <div className={classes.averageRemuneration}>
              {vacancy.remuneration > 0 && (
                <>
                  <div>
                    <p className={classes.averageText}>De:</p>
                    <p>
                      {vacancy.remuneration.toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                        maximumFractionDigits: 0,
                      })}
                    </p>
                  </div>
                  <BsArrowUpRight fill={iconColor} className={classes.icon} />
                </>
              )}

              <div>
                <p className={classes.averageText}>Até:</p>
                <p>
                  {vacancy.maxRemuneration.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                    maximumFractionDigits: 0,
                  })}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
