// extracted by mini-css-extract-plugin
export var averageRemuneration = "vacancy-salary-module--averageRemuneration--94083";
export var averageText = "vacancy-salary-module--averageText--cae06";
export var dividedRemuneration = "vacancy-salary-module--dividedRemuneration--33e4a";
export var fullContainer = "vacancy-salary-module--fullContainer--8907d";
export var icon = "vacancy-salary-module--icon--f94bf";
export var label = "vacancy-salary-module--label--e647c";
export var remunerationColumn = "vacancy-salary-module--remunerationColumn--d8b7c";
export var remunerationLine = "vacancy-salary-module--remunerationLine--78eb8";
export var smallDividedRemuneration = "vacancy-salary-module--smallDividedRemuneration--116b2";
export var smallTotal = "vacancy-salary-module--smallTotal--82f68";
export var total = "vacancy-salary-module--total--12d57";