import * as classes from './search-history.module.scss';
import { FiSearch } from '@react-icons/all-files/fi/FiSearch';
import React, { useMemo } from 'react';

export default function SearchHistory() {
  const searchHistory: string[] | undefined = useMemo(() => {
    if (typeof window !== 'undefined') {
      const searchHistory = localStorage.getItem('searchHistory');
      return searchHistory ? JSON.parse(searchHistory).reverse() : undefined;
    }
  }, []);

  return (
    <>
      {searchHistory && (
        <div className={classes.searchHistory}>
          <div className={classes.label}>Buscas Recentes</div>
          <div className={classes.searchHistoryList}>
            {searchHistory.map((search: string) => (
              <a
                href={`/vagas/?q=${search}`}
                key={search}
                aria-label={`Ir para a pesquisa recente: ${search}`}
              >
                <div>
                  <FiSearch size="23px" color="#3D3D3D" />
                </div>
                <div className={classes.label}>{search}</div>
              </a>
            ))}
          </div>
        </div>
      )}
    </>
  );
}
