import {VacancyScale} from "../sdk/entities/Vacancy";

interface OfficeDay {
  scale: VacancyScale;
  days: {
    day: number;
    active: boolean;
    start: string;
    end: string;
  }[];
  remoteDays?: {
    day: number | string;
    active: boolean;
  }[];
}

export const LONG_DAYS = {
  0: 'Domingo',
  1: 'Segunda',
  2: 'Terça',
  3: 'Quarta',
  4: 'Quinta',
  5: 'Sexta',
  6: 'Sábado',
  7: 'Domingo',
};

export const formatOfficeDayDisplay = (day: OfficeDay) => {
  if (!day.days.length) {
    return '';
  }

  let formatted = '';
  const days = day.days
    .map(d => {
      return {
        ...d,
        day: d.day === 0 ? 7 : d.day,
      };
    })
    .sort((a, b) => a.day - b.day);

  if (day.remoteDays) {
    const hasRemoteDays = !!day.remoteDays.find(day => day.active);

    if (hasRemoteDays) {
      const remote = day.remoteDays
        .filter(day => day.active)
        .map(day => {
          return typeof day.day === 'string'
            ? day.day
            : (LONG_DAYS as any)[day.day];
        })
        .join(', ');
      formatted += `<b style="color:#3976AF;">Dias remotos: ${remote}</b></br>`;
    }
  }
  let actualStart = days[0].start;
  let actualEnd = days[0].end;
  let grouped: number[] = [];

  const formatGroup = () => {
    if (grouped.length === 1) {
      formatted += `${
        (LONG_DAYS as any)[grouped[0]]
      }: ${actualStart} às ${actualEnd}`;
    } else if (grouped.length === 2) {
      formatted += `${(LONG_DAYS as any)[grouped[0]]} e ${
        (LONG_DAYS as any)[grouped[1]]
      }: ${actualStart} às ${actualEnd}`;
    } else {
      formatted += `${(LONG_DAYS as any)[grouped[0]]} a ${
        (LONG_DAYS as any)[grouped[grouped.length - 1]]
      }: ${actualStart} às ${actualEnd}`;
    }
  };

  for (let i = 0; i < 7; i++) {
    const day = days?.[i];
    if (!day?.active) {
      if (grouped.length) {
        formatGroup();
        grouped = [];
      }

      continue;
    }

    if (day.start === actualStart && day.end === actualEnd) {
      grouped.push(day.day);
    } else {
      if (grouped.length) formatGroup();
      actualStart = day.start;
      actualEnd = day.end;
      grouped = [day.day];
    }
  }

  if (grouped.length) {
    formatGroup();
  }

  return formatted;
};

export const formatOfficeEntriesAndLeaves = (day: OfficeDay) => {
  const entries: string[] = [];
  const leaves: string[] = [];

  day.days.forEach(d => {
    if (d.active) {
      entries.push(d.start);
      leaves.push(d.end);
    }
  });

  return {
    entries: [...new Set(entries)],
    leaves: [...new Set(leaves)],
  };
};

export const formatOfficeDays = (officeDays: OfficeDay[]) => {
  return officeDays.map(item => {
    const activeDays = item.days
      .filter(d => d.active)
      .map(d => (d.day === 0 ? 7 : d.day))
      .sort((a, b) => a - b);

    const isConsecutive = activeDays.every(
      (item, i) => i === 0 || item === activeDays[i - 1] + 1,
    );

    let firstDay = '';
    let lastDay = '';

    if (isConsecutive) {
      firstDay = (LONG_DAYS as any)[activeDays[0]];
      lastDay = (LONG_DAYS as any)[activeDays[activeDays.length - 1]];
    } else {
      const daysOff = item.days.filter(d => !d.active).map(d => d.day);
      if (daysOff.length === 1) {
        const dayOff = daysOff[0];
        firstDay = (LONG_DAYS as any)[item.days[dayOff].day];
        lastDay = (LONG_DAYS as any)[item.days[dayOff - 2].day];
      }
    }

    return {
      text: formatOfficeEntriesAndLeaves(item),
      full: item.days.filter(d => d.active).length === 7,
      firstDay,
      lastDay,
      ...item,
    };
  });
}
