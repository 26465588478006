import React from 'react';
import * as classes from './minimal-vacancy-card.module.scss';
import { MdLocationOn } from '@react-icons/all-files/md/MdLocationOn';
import {
  Vacancy,
  VacancyDesirableProfessionalExperienceEnum,
  VacancyScale,
} from '../../sdk/entities/Vacancy';
import { formatContractModel, getWorkplaceName } from '../../utils/vacancy';
import { formatVacancyAddress } from '../../utils/address';
import { formatOfficeDays } from '../../utils/date';
import VacancySalary from '../vacancy-salary/vacancy-salary';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { getSmallThumbImage } from '../../utils/image';

interface MinimalVacancyCardProps {
  vacancy: Vacancy;
}

const MinimalVacancyCard: React.FC<MinimalVacancyCardProps> = ({ vacancy }) => {
  const workplaceName = getWorkplaceName(vacancy);
  const image = vacancy.image ? getImage(vacancy.image) : undefined;

  const officeDays = formatOfficeDays(
    typeof vacancy.officeDays === 'string'
      ? JSON.parse(vacancy.officeDays)
      : vacancy.officeDays,
  );

  return (
    <div className={classes.vacancyCard}>
      <div className={classes.leftContent}>
        <div className={classes.leftContentLine}>
          <div className={classes.workplaceInfo}>
            {image ? (
              <GatsbyImage
                image={image}
                className={classes.logo}
                alt={`${workplaceName} logo`}
              />
            ) : (
              <img
                className={classes.logo}
                src={
                  !vacancy.confidential
                    ? getSmallThumbImage(vacancy.workplace?.image)
                    : 'https://vendoor.me/wp-content/uploads/2023/02/vendoor_logo_transparente_01.png'
                }
                alt={`${workplaceName} logo`}
              />
            )}

            <div className={classes.info}>
              <div className={classes.line}>
                <h3 className={classes.vacancyName}>{vacancy.name}</h3>
              </div>

              <div className={classes.line}>
                <h3 className={classes.workplaceName}>{workplaceName}</h3>
              </div>
            </div>

            <div className={classes.remuneration}>
              <VacancySalary vacancy={vacancy} />
            </div>
          </div>
        </div>

        <div className={classes.tagsWrapper}>
          <h4 className={classes.tag}>
            {officeDays?.[0]?.scale === VacancyScale.SCALE_12x36
              ? '12x36'
              : `${officeDays?.[0]?.firstDay} a ${officeDays?.[0]?.lastDay}`}
            {officeDays.length > 1 && ` +1`}
          </h4>
          <h4 className={classes.tag}>
            {formatContractModel(vacancy.contractModel)}
          </h4>
          <div className={classes.separator}></div>
        </div>
      </div>

      <div className={classes.rightContent}>
        <div className={classes.descriptionContainer}>
          <h4 className={classes.title}>
            {vacancy?.desirableProfessionalExperience?.includes(
              VacancyDesirableProfessionalExperienceEnum.DESIRABLE_WITHOUT_EXPERIENCE,
            ) ? (
              <p>Vaga para pessoas inexperientes</p>
            ) : (
              <p>Vaga para pessoas experientes</p>
            )}
          </h4>
          <p
            className={classes.description}
            dangerouslySetInnerHTML={{ __html: vacancy.description }}
          />
        </div>

        <h4 className={classes.address}>
          <MdLocationOn size="17px" color="#718096" />{' '}
          {formatVacancyAddress(vacancy)}
        </h4>
      </div>

      <a href={`/vagas/${vacancy.slug}/`} className={classes.link}>
        Ver detalhes da vaga
      </a>
    </div>
  );
};

export default MinimalVacancyCard;
