import React, {useMemo} from 'react';
import {Vacancy} from '../../sdk/entities/Vacancy';
import MinimalVacancyCard from '../minimal-vacancy-card/minimal-vacancy-card';

interface VacanciesListProps {
  title?: string;
  vacancies: Vacancy[];
  queryParams?: {
    positionId?: string;
    workFieldId?: string;
    positionIdName?: string;
    workFieldIdName?: string;
  };
  moreVacanciesLabel?: string;
}

const VacanciesList = (props: VacanciesListProps) => {
  const { title, vacancies, queryParams, moreVacanciesLabel } = props;

  const moreVacanciesUrl = useMemo(() => {
    if (!queryParams) return;

    const newParams = new URLSearchParams();
    const { positionId, workFieldId, workFieldIdName, positionIdName } =
      queryParams;

    if (positionId) {
      newParams.append('positionId', positionId);
    }
    if (workFieldId) {
      newParams.append('workFieldId', workFieldId);
    }
    if (workFieldIdName) {
      newParams.append('workFieldIdName', workFieldIdName);
    }
    if (positionIdName) {
      newParams.append('positionIdName', positionIdName);
    }

    return `/vagas/?${newParams.toString()}`;
  }, [queryParams]);

  return (
    <div>
      {title && (
        <h2 className="text-black text-2xl font-bold leading-[28.80px] mb-5">
          {title}
        </h2>
      )}
      <div className="flex flex-col gap-5">
        {vacancies.map(vacancy => (
          <MinimalVacancyCard vacancy={vacancy} key={vacancy.id} />
        ))}
      </div>

      {moreVacanciesUrl && (
        <div className="flex justify-center items-center">
          <a
            href={moreVacanciesUrl}
            className="h-[40px] p-3 px-4 bg-[#E1F317] rounded-full shadow items-center justify-center inline-flex mt-5 text-black text-xs font-semibold inter"
          >
            {moreVacanciesLabel || 'Ver mais vagas'}
          </a>
        </div>
      )}
    </div>
  );
};

export default VacanciesList;
