export const getSmallThumbImage = (url: string | undefined) => {
  if (!url) return '';
  if (!url.includes('https')) return url;

  const urlParts = url.split('?')[0].split('/');
  const pathFile = urlParts[urlParts.length - 1];
  const paths = pathFile.split('%2F');
  const fileName = paths[paths.length - 1];

  return url.replace(fileName, `thumbs%2F${fileName.split('.')[0]}_40x40.webp`);
};
